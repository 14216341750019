import { render, staticRenderFns } from "./alt-table.vue?vue&type=template&id=77712281&scoped=true&"
import script from "./alt-table.ts?vue&type=script&lang=ts&"
export * from "./alt-table.ts?vue&type=script&lang=ts&"
import style0 from "./alt-table.scss?vue&type=style&index=0&id=77712281&lang=scss&scoped=true&"
import style1 from "./alt-table.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77712281",
  null
  
)

export default component.exports